import React from 'react'
/* App imports */
import style from './Cookietext.module.less'

export const Cookietext = (props) => {

    const {AllButtonClicked,CustomButtonClicked}=props

    return <>       
        <p>Diese Webseite verwendet nur wenige Cookies und vergleichbare Techniken. Einige der eingesetzten Cookies sind aber für das Funktionieren der Webseite unbedingt erforderlich.</p>
        <p>Durch einen Klick auf den Button "Alles akzeptieren" stimmen Sie der Verwendung weiterer Cookies zum Website-Tracking von Drittanbietern zu. Wir verwenden diese, um zu verstehen, welche Themen Sie in diesem Blog besonders interessieren und zu welchen Themen wir mehr Content produzieren sollen.</p>
        <p>Durch einen Klick auf den Button "Ablehnen" werden nur minimal technisch notwendge Cookuies benutzt.</p>

        <p> Falls Sie mehr über die auf dieser Website verwendeten Cookies und Dienste erfahren wollen, dann klicken Sie bitte auf <a href="/datenschutz">"Mehr Informationen"</a></p>

        <p>Sie können Ihre Einstellungen jederzeit über Klick auf den Cookie-Button unten links widerrufen bzw. ändern.
            Eine fortgeführte Nutzung dieser Webseite ist von der gegebenen Einverständniserklärung gedeckt.
            Weitere Informationen erhalten Sie in den Datenschutzhinweisen unten.</p>

        <div style={{ display: "flex", width: "100%", flexDirection: 'column', alignItems: "center" }}>
            <span>
                <button className={style.cookiesButton} onClick={CustomButtonClicked}>Nur notwendige Cookies akzeptieren</button>
                <button className={style.cookiesButton} onClick={AllButtonClicked}>Alle Cookies akzeptieren</button>
            </span>
        </div>

    </>
}

export default Cookietext;